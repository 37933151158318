body {
  font-size: 1.0rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  background-color: white;
}

/*
 * Sidebar
 */

.sidebar {
  background: white;
  /* position: fixed;
  top: 0; */
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  /* background-color: rgba(0, 0, 0, .25); */
  /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25); */
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  /* background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1); */
}

.form-control-dark:focus {
  border-color: transparent;
  /* box-shadow: 0 0 0 3px rgba(255, 255, 255, .25); */
}




 .footer-bg {
  display: block;
  position: relative;
 
  bottom: -20px;
  height: 200px;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1792 200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="rgba(255, 255, 255, 1)" d="M 0 53 C 241.5 53 241.5 93 483 93 L 483 93 L 483 0 L 0 0 Z" stroke-width="0"></path> <path fill="rgba(255, 255, 255, 1)" d="M 482 93 C 699.5 93 699.5 61 917 61 L 917 61 L 917 0 L 482 0 Z" stroke-width="0"></path> <path fill="rgba(255, 255, 255, 1)" d="M 916 61 C 1166.5 61 1166.5 95 1417 95 L 1417 95 L 1417 0 L 916 0 Z" stroke-width="0"></path><path fill="rgba(255, 255, 255, 1)" d="M 1416 95 C 1604 95 1604 44 1792 44 L 1792 44 L 1792 0 L 1416 0 Z" stroke-width="0"></path></svg>');
 
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #212121;
  border: 0px solid black;
 }
 
 @media (max-width:1095px) {
  .footer-bg {
    background-size: contain;
    border: 0px solid black;
  }
 }


 #btn-default2 {
  font-family: Raleway-SemiBold;
  font-size: 13px;
  color: #888888;
  letter-spacing: 1px;
  line-height: 15px;
  border: 1px solid #DDDDDD;
  border-radius: 40px;
  background: transparent;
  transition: all 0.3s ease 0s;
  width: 100px;
  
}

#btn-default2:hover {
  
  border-radius: 40px;
  color: #FFF;
  background: #727272;
  border: 1px solid #DDDDDD;
 
}


.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
  }
  .table-wrapper-scroll-y {
  display: block;
  }
 